import { motion } from "framer-motion";


const AnimeLogo = ({ imgUrl, classProps }) => {
    return (
        <motion.div
            animate={{ y: [-10, 10] }}
            transition={{

                repeat: Infinity,
                duration: 1,
                ease: 'easeInOut',
                repeatType: 'reverse',
            }}

            className="w-full md:w-[400px] p-2"><img src={`../img/${imgUrl}`} alt={imgUrl}  className={`${classProps}`} /></motion.div>

    );
}

export default AnimeLogo;